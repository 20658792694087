<template>
  <div>
    <div style="width: 70%;margin: 0 auto">
      <el-row style="width: 100%;margin-top: 2%" :gutter="30">
        <el-col :md="5" :xs="24">
          <img style="width: 100%" src="@/assets/images/logo/favicon.png">
        </el-col>
        <el-col :span="15" v-if="!isMobile">
          <div style="font-weight: 650;font-size: 30px;color: #803e9d;line-height: 85px">
            第三届趣宠会——暨2025天津国际宠物产业博览会
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="width: 100%;min-height:80%;background-color:#fbf2fd;padding: 30px 0 ">
      <div class="cardBox" :style="{width:isMobile?'95%':'70%'}">
        <div style="width:100%;height: 100px;background-color: #e5d2fd;text-align: center">
          <div style="line-height: 100px;font-size: 30px;font-weight: 650;color: #803e9d">
            —— 售票申请 ——
          </div>
        </div>
        <div style="width:100%;background-color: #fff;text-align: center;padding: 20px">
          <div :style="{width: isMobile?'100%':'80%',margin:'0 auto',color: '#803e9d'}">
            <el-steps :active="active" align-center style="color: #803e9d !important;">
              <el-step title="注册"></el-step>
              <el-step title="售票申请"></el-step>
              <el-step title="提交成功"></el-step>
            </el-steps>
          </div>
          <div :style="{width: isMobile?'95%':'40%',margin:'30px auto','min-height': '400px'}" v-loading="loading">
            <template v-if="active==0">
              <el-form ref="form" :model="form" label-width="100px" :rules="rules">
                <el-form-item label="手机号" prop="account">
                  <el-input v-model="form.account"></el-input>
                </el-form-item>
                <el-form-item label="短信验证码" prop="verCode">
                  <el-input v-model="form.verCode" class="code">
                    <template #suffix>
                      <el-button @click="getCode" style="border: none;color:#803e9d">
                       {{ codeName }}
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                  <el-input v-model="form.password"  show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="passwordVal">
                  <el-input v-model="form.passwordVal" show-password></el-input>
                </el-form-item>
              </el-form>
              <el-button style="width: 50%;background-color:#e5d2fd;color: #0f0f11;font-weight: 600"
                         round @click="register">
                注册&emsp; <i class="el-icon-right el-icon--right"></i>
              </el-button>
            </template>
            <template v-if="active==1">
              <el-form ref="form2" :model="form" :label-position="isMobile?'top':'right'" label-width="140px" :rules="rules2">
                <el-form-item label="售票商类型" prop="ticketSellerType">
                  <el-radio-group v-model="form.ticketSellerType">
                    <el-radio label="01">企业</el-radio>
                    <el-radio label="02">个人</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item :label="form.ticketSellerType=='01'?'企业名称':'姓名'" prop="sellerName">
                  <el-input v-model="form.sellerName"></el-input>
                </el-form-item>
                <el-form-item :label="form.ticketSellerType=='01'?'统一社会信用代码':'证件号'" prop="unifiedSocialCreditIdentifier">
                  <el-input v-model="form.unifiedSocialCreditIdentifier"></el-input>
                </el-form-item>
                <el-form-item label="经营地址" v-if="form.ticketSellerType=='01'" prop="businessAddress">
                  <el-input v-model="form.businessAddress"></el-input>
                </el-form-item>
                <el-form-item :label="form.ticketSellerType=='01'?'营业执照':'身份证'">
                  <el-row :gutter="10">
                    <el-col :span="11">
                      <el-upload
                          :class="showBtnImg1?'show':'hide'"
                          action="#"
                          accept=".jpeg,.jpg,.png"
                          list-type="picture-card"
                          :auto-upload="false"
                          :on-change="imgChange1"
                          :on-remove="handleImgRemove1"
                          :on-preview="handlePictureCardPreview">
                        <i class="el-icon-plus" style="font-size: 15px"></i>
                        <span>{{form.ticketSellerType=='01'?'营业执照':'身份证正面'}}</span>
                      </el-upload>
                    </el-col>
                    <el-col :span="11" v-if="form.ticketSellerType=='02'">
                      <el-upload
                          :class="showBtnImg2?'show':'hide'"
                          action="#"
                          accept=".jpeg,.jpg,.png"
                          list-type="picture-card"
                          :auto-upload="false"
                          :on-change="imgChange2"
                          :on-remove="handleImgRemove2"
                          :on-preview="handlePictureCardPreview">
                        <i class="el-icon-plus" style="font-size: 15px"></i>
                        <span>身份证背面</span>
                      </el-upload>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="开户行" prop="openingBank">
                  <el-input v-model="form.openingBank"></el-input>
                </el-form-item>
                <el-form-item label="银行账号" prop="bankAccount">
                  <el-input v-model="form.bankAccount"></el-input>
                </el-form-item>
              </el-form>
              <el-button style="width: 50%;background-color:#e5d2fd;color: #0f0f11;font-weight: 600" round @click="submitInfo">
                提交申请
              </el-button>
            </template>
            <template v-if="active==3">
              <img style="width: 40%" src="@/assets/images/register/img-ok.png">
              <div style="color:#803e9d ">
                您的申请已提交成功，预计5个工作日审核完成，请随时关注!
              </div>
            </template>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </div>
        </div>
      </div>
    </div>

<!--    页脚部分-->
    <div class="copyright-area copyright-style-one variation-two">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-3">
            <img src="@/assets/images/logo/favicon.png" style="width: 60%;display: block;margin: 0 auto;">
          </div>
          <div class="col-lg-5">
            <ul style="list-style: none;padding: 0px;">
              <li style="font-size: 14px;color: #9618D3;font-weight: bold;line-height: 40px;">联系我们
                <ul style="list-style: none;padding: 0px;">
                  <li style="font-size: 13px;color: #9618D3;font-weight: bold;">
                    <img src="@/assets/images/img/footer/icon1.png">
                    <span style="margin-left: 10px;">022-88525566</span>
                  </li>
                  <li style="font-size: 13px;color: #9618D3;font-weight: bold;">
                    <img src="@/assets/images/img/footer/icon2.png">
                    <span style="margin-left: 10px;">www.funpetfair.com</span>
                  </li>
                  <li style="font-size: 13px;color: #9618D3;font-weight: bold;">
                    <img src="@/assets/images/img/footer/icon3.png">
                    <span style="margin-left: 10px;">天津市津南区津南开发区管委会9号楼1楼</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/images/img/footer/fotCode.png">
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="background: #9618D3;color: #fff;font-size: 13px;line-height: 50px;text-align: center">
      <div class="col-lg-3"></div>
      <div class="col-lg-2">Copyright©华宠展览（天津）有限公司</div>
      <div class="col-lg-2">备案号：津ICP备2023001697号</div>
      <div class="col-lg-2">
        <img src="@/assets/images/img/footer/bei.png" style="width:20px;padding-bottom: 3px;"/>&nbsp;
        津公网安备 12011202000726号
      </div>
      <div class="col-lg-2"></div>
    </div>
  </div>
</template>

<script>
import Footer from "../components/common/footer/Footer";
import {Api} from "../utils/api";

export default {
  name: "SellerRegister",
  components: {Footer},
  props: {
    footerStyle: {
      type: Number,
      default: 1
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showCopyright: {
      type: Boolean,
      default: true
    },
    showNewsletter: {
      type: Boolean,
      default: false
    },
    showCta: {
      type: Boolean,
      default: true
    },
    selectIndex: {
      type: Number
    }
  },
  created() {
    this.isMobile = window.matchMedia("(max-width: 425px)").matches;
  },
  data() {
    return {
      isMobile:false,
      active: 0,
      form: {
        ticketSellerType:'01',
      },
      show: true,
      count: 60,
      loading: false,
      dialogVisible: false,
      showBtnImg1:true,
      showBtnImg2:true,
      dialogImageUrl: '',
      totalTime: 60, //一般是60
      timer:'', //定时器
      codeName:'发送验证码',
      file1:undefined,
      file2:undefined,
      rules: {
        account: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur'}
        ],
        verCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        passwordVal: [
          {required: true, message: '请确认密码', trigger: 'blur'}
        ],
      },
      rules2: {
        sellerName: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        unifiedSocialCreditIdentifier: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        businessAddress: [
          {required: true, message: '请输入经营地址', trigger: 'blur'}
        ],
        openingBank: [
          {required: true, message: '请输入开户行', trigger: 'blur'}
        ],
        bankAccount: [
          {required: true, message: '请输入银行账号', trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    register() {
       this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.password != this.form.passwordVal) {
            this.$message.error('两次输入密码不一致');
            return false;
          }
          this.loading=true
          Api.sellerRegister( this.form).then(res=>{
            if(res.success){
              //this.form.userId=res.data
              //this.$message.success('注册成功');
              this.active = 1
            }else {
              this.$message.error(res.message);
            }
            this.loading=false

          })

        } else {
          return false;
        }
      });

    },
    submitInfo(){
      if(this.form.ticketSellerType=='01'){
        if(!this.file1){
          this.$message.error('请先上传营业执照');
          return false;
        }
      }else {
        if(!this.file1||!this.file2){
          this.$message.error('请先上传身份证照片');
          return false;
        }
      }
      this.$refs['form2'].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("file1",this.file1.raw)
          formData.append("file2",this.file2?.raw)
          //formData.append("userId",this.form.userId)
          formData.append("account",this.form.account)
          formData.append("password",this.form.password)
          formData.append("ticketSellerType",this.form.ticketSellerType)
          formData.append("sellerName",this.form.sellerName)
          formData.append("unifiedSocialCreditIdentifier",this.form.unifiedSocialCreditIdentifier)
          formData.append("businessAddress",this.form.businessAddress)
          formData.append("openingBank",this.form.openingBank)
          formData.append("bankAccount",this.form.bankAccount)
          this.loading=true
          Api.sellerUpdateInfo(formData).then(res=>{
            if(res.success){
              this.$message.success('操作成功');
              this.active = 3
            }else {
              this.$message.success(res.message);
            }
            this.loading=false

          })

        } else {
          return false;
        }
      });

    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleImgRemove1(file,fileList){
      this.showBtnImg1 = fileList.length < 1;
      this.file1=undefined;
    },
    imgChange1(file, fileList){
      this.file1=file;
      this.showBtnImg1 = fileList.length < 1;
    },
    handleImgRemove2(file,fileList){
      this.showBtnImg2 = fileList.length < 1;
      this.file2=undefined;
    },
    imgChange2(file, fileList){
      this.file2=file;
      this.showBtnImg2 = fileList.length < 1;
    },
    //发送验证码
    async getCode() {
      if(! this.show){
        return false;
      }
      if (this.form.account) {
        this.show = false;
        let res=await Api.sellerSendMsg({mobilePhone: this.form.account})
        if(res.success){
          this.$message.success('发送成功');
          this.countDown()
        }else {
          this.$message.error(res.message);
        }

      }else {
        this.$message.error('请先输入手机号');
      }

    },
    // 验证码倒计时
    countDown() {
      this.show = false
      this.codeName = this.totalTime + 's后重新发送'
      this.timer = setInterval(() => {
        this.totalTime--
        this.codeName = this.totalTime + 's后重新发送'
        if (this.totalTime < 0) {
          clearInterval(this.timer)
          this.codeName = '重新发送验证码'
          this.totalTime = 60
          this.show = true
        }
      }, 1000)

    },
  }
}
</script>

<style scoped>
.cardBox {
  width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  text-align: center;
  margin: 10px auto;
}

/deep/ body input[type=text] {
  border: 1px solid #DCDFe6;
  border-radius: 15px;
}

.code :deep .el-input__wrapper {
  padding-right: 1px;
}
.el-button {
  background: rgba(0,0,0,0);
}
/deep/.el-step__head.is-finish {
  color: #803e9d;
  border-color: #803e9d;
}
/deep/.el-step__title.is-finish {
  color: #803e9d;
}
/deep/.hide  .el-upload--picture-card{
    display: none;

}

</style>
