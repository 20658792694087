<template>
  <Layout :selectIndex="1">
    <!--轮播图-->
    <carousel />
    <!--日程安排-->
    <div class="rwt-portfolio-area rn-section-gap">
      <div class="container">
        <div style="margin: 0 auto;width: 200px">
          <img src="@/assets/images/logo/logo.png" style="display: block;width: 70px;margin: 0px auto 10px;">
          <h3 style="text-align: center;line-height: 30px;">日程安排</h3>
        </div>
        <div class="row">
          <div class="col-lg-12" v-for="item in workData" :key="item" style="border-bottom: 1px solid #f0f0f0;">
            <h5 style="margin: 0px;line-height: 50px; color: #9618D3;text-align: center">{{item.name}}</h5>
            <p style="color: #000;font-weight: normal;text-align: center;line-height: 40px;" v-html='item.time'/>
          </div>
          <div class="col-lg-12">
            <div class="thumbnail" style="height: 400px;overflow: hidden">
              <div id="allmap" ref="allmap" style="height: 400px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>



<script>
import carousel from  "../components/elements/carousel/carousel";
import Layout from "../components/common/Layout";
let map;
export default {
  name: 'InstitutionalFramework',
  components: {carousel,Layout },
  data() {
    return {
      workData: [
        {
          name: '展会时间：2025年4月18日-20日',
          time: '18日（星期五）9:00-17:00 观众16:30 停止入场 <br/>19日（星期六） 9:00-17:00 观众16:30 停止入场 <br/>20日（星期日） 9:00-16:00 观众15:00 停止入场'
        },
        {
          name: '展会地点',
          time: '国家会展中心（天津）'
        },

      ]
    }
  },
  mounted() {
    this.$nextTick(
        this.getMap()
    )
  },
  methods: {
    getMap(){
      map = new window.BMapGL.Map(this.$refs.allmap);
      var point = new window.BMapGL.Point(117.390000,39.03426);
      // var myIcon = new BMapGL.Icon( '图片地址', new BMapGL.Size(32, 32), {
      //   anchor: new BMapGL.Size(10, 25),
      // });
      // var marker = new BMapGL.Marker(point, {icon: myIcon});
      var marker = new BMapGL.Marker(point);
      map.addOverlay(marker);
      map.centerAndZoom(point, 15);
      map.enableScrollWheelZoom(true);
    },
  }
}
</script>
<style>

</style>
