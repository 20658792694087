<template>
  <div style="height: 100vh; width: 100vw; background-color: #F1DEFC; overflow-y: auto">

    <div class="container-header">
      <img src="@/assets/images/audit/header.png" />
    </div>

    <div v-loading="loading" class="container-form">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px" label-position="left">

        <div class="container-form-area" v-if="auditType + '' === '1' || auditType + '' === '2'">
          <img src="@/assets/images/audit/title0.png" />
          <div class="container-form-area-body">
            <el-form-item label="审核结果">
              <span v-if="auditType + '' === '1'">审核中，请耐心等候！</span>
              <span v-if="auditType + '' === '2'" style="color: red;">申请已被驳回，请重新申请！</span>
            </el-form-item>
            <el-form-item label="审批意见" v-if="auditOpinion">
              {{auditOpinion}}
            </el-form-item>
          </div>
        </div>

        <div class="container-form-area" id="targetBox">
          <img src="@/assets/images/audit/title1.png" />
          <div class="container-form-area-body">
            <el-form-item label="姓名" prop="ticketBuyerName">
              <el-input placeholder="请填写您的真实姓名" v-model="form.ticketBuyerName" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="ticketBuyerDocumentNumber">
              <el-input placeholder="请准确填写证件号，否则无法入场" v-model="form.ticketBuyerDocumentNumber" :disabled="disabled"></el-input>
            </el-form-item>
          </div>
        </div>

        <div class="container-form-area">
          <img src="@/assets/images/audit/title2.png" />
          <el-row v-for="(item, index) in billTopicList" class="container-form-area-row" :id="'targetTopic' + index">
            <el-col :span="24" class="container-form-area-row-title">
              <span style="color: #9801DC;">*</span>
              {{ item.title }}
            </el-col>
            <el-col :span="24" class="container-form-area-row-content">
              <el-radio-group v-if="item.resultType === '01'" v-model="item.remark" :disabled="disabled">
                <el-radio-button class="container-form-area-row-content-check-button" v-for="r in item.options" :value="r.id" :label="r.id">
                  {{ r.topicOption }}
                </el-radio-button>
              </el-radio-group>
              <el-checkbox-group v-if="item.resultType === '02'" v-model="item.remark" :disabled="disabled">
                <el-checkbox-button class="container-form-area-row-content-check-button" v-for="r in item.options" :key="r.id" :label="r.id">
                  {{ r.topicOption }}
                </el-checkbox-button>
              </el-checkbox-group>
              <el-input v-if="item.resultType === '03'" type="textarea" v-model="item.remark" :disabled="disabled"></el-input>
              <div v-if="item.resultType === '04'">
                <el-upload
                    list-type="picture-card"
                    :limit="6"
                    action="#"
                    :file-list="fileList"
                    :http-request="handleFileUpload"
                    :on-remove="handleRemove"
                    :disabled="true"
                    :class="disabled ? 'upload-disabled' : 'upload-usable'"
                >
                  <i class="el-icon-plus">上传图片</i>
                </el-upload>
              </div>
            </el-col>
          </el-row>

          <el-button class="form-button" type="primary" @click="handleSubmit" :loading="loading" v-if="!disabled">提交</el-button>
<!--          <el-button class="form-button" type="primary" @click="goBack">返回</el-button>-->

        </div>
      </el-form>
    </div>

<!--    <div class="container-footer">-->
<!--      <img src="@/assets/images/audit/footer.jpg" />-->
<!--    </div>-->

  </div>
</template>

<script>
import {Api} from "../../utils/api";

export default {
  name: "SpecialAudit",
  data() {
    return {
      form: {},
      rules: {
        ticketBuyerName: [{required: true, message: '请填写您的真实姓名', type: 'string', trigger: 'change'}],
        ticketBuyerDocumentNumber: [
          {required: true, message: '请准确填写证件号，否则无法入场', type: 'string', trigger: 'change'},
          {
            pattern: /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/,
            message: "请准确填写证件号，否则无法入场",
            trigger: "change"
          }
        ],
        ticketBuyerPhone: [
          {required: true, message: '请填写真实手机号', type: 'string', trigger: 'change'},
          {pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: "请填写真实手机号", trigger: "change"}
        ],
      },
      billTopicList: [],
      loading: false,
      fileList: [],
      code: null,
      openid: null,

      auditType: 0,
      disabled: false,
      auditOpinion: null,

      ticketSellerInfoId: "0ade924fab0b420ae81a88c6cda00d63152c6447e6a524ef403aec740d25f994",
    }
  },
  mounted() {
    this.form.ticketBuyerPhone = this.getUrlParam("ticketBuyerPhone");
    this.ticketSellerInfoId = this.getUrlParam("ticketSellerInfoId");

    console.log("ticketSellerInfoId:", this.ticketSellerInfoId);

    this.loading = true;
    Api.specialAuditStatus({
      ticketBuyerPhone: this.form.ticketBuyerPhone
    }).then(res => {
      console.log("111111", res)
      this.auditType = res.data.code;
      if (res.data.code === "3") {
        this.$router.push("/SpecialCode?purchaseId=" + res.data.purchaseId);
        return;
      }
      this.disabled = (this.auditType + "" === "1");

      Api.ticketPurchaseInfoWeb({
        ticketBuyerPhone: this.form.ticketBuyerPhone
      }).then(res => {
        if (res.ticketBuyerName) {
          this.$set(this.form, "ticketBuyerName", res.ticketBuyerName);
          this.$set(this.form, "ticketBuyerDocumentNumber", res.ticketBuyerDocumentNumber);
        }
        if (res.auditOpinion) {
          this.auditOpinion = res.auditOpinion;
        }
        this.getBillTopicList();
      })

    })
  },
  methods: {
    getUrlParam(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    getBillTopicList() {
      this.loading = true;
      Api.getBillTopicList({
        ticketBuyerPhone: this.form.ticketBuyerPhone
      }).then(res => {
        this.loading = false;
        res.forEach(e => {
          if (e.resultType === "01") {
            e.remark = e.options.filter(f => f.checked).map(f => f.id).join(",");
          }
          if (e.resultType === "02") {
            e.remark = e.options.filter(f => f.checked).map(f => f.id);
          }
          if (e.resultType === "04") {
            let fileUrls = e.fileUrls;
            let fileInfoIds = e.fileInfoIds;
            for (let i = 0; i < fileInfoIds.length; i++) {
              this.fileList.push({
                fileId: fileInfoIds[i],
                url: fileUrls[i]
              })
            }
          }
        })
        this.billTopicList = res;
        this.$nextTick(() => {
          // this.$refs.form.clearValidate();
        })
      })
    },
    handleFileUpload(e) {
      if (e.file.size > 1024 * 1024 * 10) {
        this.$message.error("图片尺寸不得超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("file", e.file);
      this.loading = true;
      Api.uploadImageWeb(formData).then(res => {
        this.fileList.push(res.data);
        this.loading = false;
      })
    },
    handleRemove(e) {
      this.fileList = this.fileList.filter(f => f.uid !== e.uid);
    },
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let billTopicList = this.billTopicList;
          let topicAnswers = [];
          for (let index = 0; index < billTopicList.length; index++) {
            let e = billTopicList[index];
            let number = index + 1;
            if (e.resultType === "01") {
              if (!e.remark) {
                this.$message.error("请完成第" + number + "题");
                document.getElementById("targetTopic" + index).scrollIntoView();
                return;
              } else {
                e["answer"] = e.remark;
              }
            } else if (e.resultType === "02") {
              if (e.remark.length === 0) {
                this.$message.error("请完成第" + number + "题");
                document.getElementById("targetTopic" + index).scrollIntoView();
                return;
              } else {
                e["answer"] = e.remark.join(",");
              }
            } else if (e.resultType === "03") {
              if (!e.remark) {
                this.$message.error("请完成第" + number + "题");
                document.getElementById("targetTopic" + index).scrollIntoView();
                return;
              } else {
                e["answer"] = e.remark;
              }
            } else {
              if (this.fileList.length === 0) {
                this.$message.error("请完成第" + number + "题");
                document.getElementById("targetTopic" + index).scrollIntoView();
                return;
              } else {
                e["answer"] = this.fileList.map(f => f.fileId).join(",");
              }
            }
            topicAnswers.push({
              id: e.id,
              answer: e.answer,
              options: e.options,
              resultType: e.resultType,
              displayOrder: e.displayOrder,
            })
          }
          let form = {};
          Object.assign(form, this.form);
          form["topicAnswers"] = topicAnswers;
          form["ticketSellerInfoId"] = this.ticketSellerInfoId;
          this.loading = true;
          Api.specialAuditWeb(form).then(res => {
            if (res.success) {
              this.loading = false;
              this.form = {};
              this.$router.push("/AuditSuccess");
            } else {
              this.loading = false;
            }
          }).catch(() => {
            this.loading = false;
          })
        } else {
          document.getElementById("targetBox").scrollIntoView();
        }
      })
    },
    goBack() {
      this.$router.back();
    },
  }
}
</script>

<style scoped>

.container-header {
  /*//position: fixed;
  //top: 0;*/
  width: 100vw;
  height: calc(100vw * 678 / 1242);
}
.container-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: calc(100vw * 263 / 1080);
}

.container-form {
  /*//position: fixed;
  //top: 55vw;
  //height: calc(100vh - 54vw);
  //overflow-y: auto;*/
  width: 90vw;
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
::-webkit-scrollbar {
  width: 0;
}
.container-form /deep/ .el-input__inner {
  background-color: white;
  border: #9801DC 1px solid;
  border-radius: 5px;
  font-size: 14px;
  height: 40px;
}
.container-form /deep/ .el-input.is-disabled .el-input__inner {
  background-color: #F5F7FA;
  border: #9801DC 1px solid;
  border-radius: 5px;
  font-size: 14px;
  height: 40px;
}

.container-form-area {
  background-color: white;
  width: 90vw;
  border-radius: 10px;
  margin-bottom: 20px;
}
.container-form-area img {
  border-radius: 10px 10px 0 0;
}
.container-form-area-body {
  padding: 0 20px;
  padding-bottom: 10px;
}
.container-form-area-body /deep/ .el-form-item__label {
  color: black;
  font-weight: bold;
}
.container-form-area-body /deep/ .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  color: #9801DC;
}
.container-form-area-row {
  padding: 0 20px;
  font-size: 14px;
}
.container-form-area-row-title {
  color: black;
  font-weight: bold;
}
.container-form-area-row-content {
  margin: 5px 0 10px 0;
}
.container-form-area-row-content /deep/ .el-textarea {
  background-color: white;
  border: #9801DC 1px solid;
  border-radius: 5px;
  font-size: 14px;
}
.container-form-area-row-content /deep/ .el-upload--picture-card {
  background-color: #f0defb;
  border: none;
}
.container-form-area-row-content /deep/ .el-icon-plus {
  font-size: 14px;
  color: #9801DC;
}
.container-form-area-row-content /deep/ .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
.container-form-area-row-content /deep/ .el-checkbox-group label {
  width: auto;
}
.container-form-area-row-content-check-button {
  margin-bottom: 5px;
  margin-right: 5px;
}
.container-form-area-row-content-check-button /deep/ .el-checkbox-button__inner {
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  background-color: #f0defb;
  color: #9801DC;
  border-radius: 5px;
  border: none;
}
.is-checked /deep/ .el-checkbox-button__inner {
  background-color: #9801DC;
  color: white;
  border: none;
  box-shadow: none;
}
.container-form-area-row-content-check-button /deep/ .el-radio-button__inner {
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  background-color: #f0defb;
  color: #9801DC;
  border-radius: 5px;
  border: none;
}
.is-active /deep/ .el-radio-button__inner {
  background-color: #9801DC;
  color: white;
  border: none;
  box-shadow: none;
}
.container-form-area-row-content-check-button /deep/ .el-radio-button__orig-radio:disabled:checked+.el-radio-button__inner {
  background-color: #9801DC;
}
.upload-disabled /deep/ .el-upload--picture-card {
  display: none;
}

.form-title {
  background-color: #f0defb;
  padding: 5px 10px;
  font-weight: bold;
  width: 100vw;
  display: flex;
}

.form-button {
  width: 80vw;
  margin: 10px 5vw 10px 5vw;
  background-color: #9801DC;
  color: white;
  border: none;
}

</style>
