<template>

  <!--<Layout :selectIndex="2" :footer-style="0">
    <div class="mask" v-show="isShowSlide" @click.self="isShowSlide = false">
      <div class="slideContainer">
        <SlideVerify ref="slideblock" :successFun="successVerify"></SlideVerify>
      </div>
    </div>
    <carousel/>-->
  <div>
    <h4 style="color: #9618D3;text-align: center;margin-top: 50px;">趣宠会——观展购票</h4>
    <img style="width: 250px;display: block;margin: 60px auto 20px;" src="@/assets/images/img/img.png">
    <p style="color: #000;text-align: center;margin: 0px;padding: 0px">PC端请扫描二维码购票</p>
    <p style="color: #000;text-align: center;margin: 10px;padding: 0px">手机端可直接点击下方按钮</p>
    <div style="width: 200px;margin: 0 auto;padding-bottom: 50px">
      <el-button  style="width: 100%;color: #fff;background-color: #9618D3" type="primary" plain @click="openWechat">立即购票</el-button>
    </div>
  </div>
<!--  </Layout>-->
</template>

<script>
  import Layout from '../../components/common/Layout.vue'
  import AboutFour from "../../components/elements/about/AboutFour.vue";
  import carousel from "../../components/elements/carousel/carousel.vue";
  import SlideVerify from "../../components/elements/about/SlideVerify.vue";
  import { Api } from '@/utils/api';
  export default {
    name: 'ticketing',
    components: { Layout,AboutFour,carousel,SlideVerify},
    data() {
      return {
        url:'',
        form:{},
        isShowSlide: false,
      }
    },
    mounted() {
     this.init()
    },
    methods:{
      init(){
        let clickid=this.$route.query.clickid
        let adid=this.$route.query.adid
        let creativeid=this.$route.query.creativeid
        let creativetype=this.$route.query.creativetype
        let sellerId=this.$route.query.weId
        let source=this.$route.query.source
        this.form.adId=window.location.href
        if(clickid){
          this.form.clickId=clickid
          this.form.creaticeId=creativeid
          this.form.creativeType=creativetype
          this.form.sellerId=sellerId
          this.form.source=source
        }else if (sellerId){
          this.form.sellerId=sellerId
          this.form.source=source
        }
        Api.getScheme(this.form).then(res => {
          this.url=res;
          window.location.href = res;
        });
        //window.location.href ="weixin://dl/business/?t=yxDoKVFZKCm"
      },
      openWechat(){
        if (this.url){
          window.location.href = this.url;
        }else {
          this.init()
        }

      },
      successVerify() {
        this.isShowSlide = false;
        Api.addVisiter(this.form).then(res => {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
          this.form = {}
          this.$refs['ruleForm'].resetFields();
        });
      }
    }
  }
</script>
<style>
.mask {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}
.mask .slideContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 380px;
  height: 250px;
  background-color: rgb(255, 255, 255);
}
  .el-input--medium .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 2px;
    width: 100%;
  }
  .el-input--medium {
    font-size: 14px;
    width: 100%;
  }
  .el-select {
    display: inline-block;
    position: relative;
    width: 100%;
  }
  p{
      padding: 0px;
      margin: 0px;
      text-align: left;
  }
</style>
