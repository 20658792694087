<template>
  <div>
    <div class="d-block d-lg-none">
      <el-carousel :interval="5000" arrow="always" height="150px">
        <el-carousel-item v-for="item in imageList" :key="item.url">
          <img style="width: 100%;" :src="item.image" alt="Corporate images"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="d-none d-lg-block">
      <el-carousel :interval="5000" arrow="always" height="480px" style="margin-top: 1px;">
        <el-carousel-item v-for="(item, index) in imageList" :key="index">
          <img style="width: 100%;height: 480px" :src="item.image" alt="Corporate images"/>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>



<script>

export default {
  name: 'exhibitionOverview',
  data() {
    return {
      imageList: [
        {
          image: require('@/assets/images/img/banner-1.jpg'),
          title: 'Business Consulting',
          badge: '',
        },
        {
          image: require('@/assets/images/img/banner-2.jpg'),
          title: 'Business Consulting',
          badge: '',
        },
        {
          image: require('@/assets/images/img/banner-3.jpg'),
          title: 'Business Consulting',
          badge: '',
        },
      ],

    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style>

</style>
